import React from 'react'

import { Link } from 'gatsby'
import Layout from '../../components/english_layout'
import SEO from '../../components/seo'
import Banner from '../../components/banner'

import '../../styles/about.sass'

const JoinPage = () => {
    return (
        <Layout languageSwitchLink="/join">
            <SEO title="Participate" />
            <Banner class_suffix="deep" />
            <div className="about-container">
                <div className="about-content">
                    <p>
                        Join our community to develop your cybersecurity skills!
                    </p>
                    <a href="https://discord.gg/ZcYnS3GZE6">
                        <button className="signup-button">Join our Discord</button>
                    </a>
                    <h1>Technical Workshops</h1>
                    <p>
                    These workshops aim to investigate various themes and improve the participants' skills. These workshops are open to beginners and veterants.
                    </p>
                    <h1>Get Involved!</h1>
                    Getting involved in a student comity allows to gain a good experience outside of the university, which is what recruiters are looking for!
                    <h2>Executive Team</h2>
                    <p>
                    A gathering of motivated and passionate student of Polytechnique Montreal, the executive team is at the heart of the comity. They plan the technical workshops animated by other students or experts, they organise CTF (Capture-the-Flag) competitions with our sponsors, promote the comity on social media and build relationships with companies through partnerships.
                    </p>
                    <a href="https://forms.gle/Q5PedtHzsSjAjCLn6">
                        <button className="signup-button">Join our Executive Team!</button>
                    </a>
                    <h2>CTF Team</h2>
                    <p>
                    During the whole year, the CTF team participates in numerous competitions. These include <a href={"https://nsec.io/"}>NorthSec</a> (Montréal), the <a href={"https://hfctf.ca/"}>HackFest</a> (Québec) and the <a href={"http://www.csaw.io/"}>CSAW</a>.
                    Members of the CTF team have access to entries provided by the comity. Check out our ranking on <a href={"https://ctftime.org/team/59057"}>CTFTime</a>!
                    </p>
                    <p>
                        To join our CTF Team you have to be a registered student of Polytechnique Montreal.
                    </p>
                    <a href="https://forms.gle/QJMzWpF6hUJsw9W8A">
                        <button className="signup-button">Join our CTF Team!</button>
                    </a>
                </div>
            </div>
        </Layout>
    )
}
export default JoinPage
